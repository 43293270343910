import PopupRoot_ClassicComponent from '@wix/thunderbolt-elements/src/components/PopupRoot/viewer/skinComps/Classic/Classic.skin';


const PopupRoot_Classic = {
  component: PopupRoot_ClassicComponent
};


export const components = {
  ['PopupRoot_Classic']: PopupRoot_Classic
};

